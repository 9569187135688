
import s from './Navigation.module.scss'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {checkPathLocation} from "../../hooks/checkPathLocation";
import {useResize} from "../../hooks/useResize";
import {useState} from "react";


export const Navigation = () => {
    const path = useLocation()
    const navigate = useNavigate()
    const location = checkPathLocation(path)
    const width = useResize()
    const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false)

    const navLinks = [
        {link: '/', title: 'Главная'},
        {link: '/products', title: 'Продукты'},
        {link: '/restaurant', title: 'Ресторан'},
        {link: '/couriers', title: 'Курьеры'},
        {link: '/active-orders', title: 'Активные заказы'},
        {link: '/promocodes', title: 'Промокоды'},
        {link: '/complete-orders', title: 'Выполненные заказы'},
    ]
    const title = navLinks.find((i) => {
        if (i.link.slice(1) === location) {
            return i
        }}
    )
    if (width > 600) {
        return (
        <div className={s.wrapper}>

            <nav className={s.nav}>
                <h2 onClick={() => navigate('/')}>Навигация</h2>
                {navLinks.map((item) => (
                    <Link to={item.link} className={location === item.link.slice(1) ? s.active : ''}>{item.title}</Link>
                ))}
            </nav>
        </div>
    )}

    return (
        <>
        <div className={s.mobileWrapper}>
            <div className={s.header}>
                {title !== undefined && title.title}
                <div
                    className={s.burger}
                    onClick={() => setIsOpenBurgerMenu(true)}
                ></div>
            </div>

            {isOpenBurgerMenu &&
                <nav className={s.burgerWrapper}>
                    <div
                        className={s.burgerExitButton}
                        onClick={() => setIsOpenBurgerMenu(false)}
                    >
                        <svg width="128" height="128" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.11 2.697L2.698 4.11 6.586 8l-3.89 3.89 1.415 1.413L8 9.414l3.89 3.89 1.413-1.415L9.414 8l3.89-3.89-1.415-1.413L8 6.586l-3.89-3.89z"
                                fill="#fff"
                            ></path>
                        </svg>

                    </div>
                    <div className={s.mobileNav}>
                        {navLinks.map((item) => (
                            <Link to={item.link}
                                  className={location === item.link.slice(1) ? s.active : ''}
                                  onClick={() => setIsOpenBurgerMenu(false)}
                            >{item.title}</Link>
                        ))}
                    </div>
                </nav>
            }
        </div>
        </>
    )
}