import { create } from 'zustand'
import {devtools} from 'zustand/middleware'

export const useCouriersStore = create(devtools((set) => (
            {
                couriers: [],
                setCouriers: async (couriers) => {
                    set({couriers: couriers})
                },

            }
        ),
        {
            name: "couriers-storage"
        }
    )
)


