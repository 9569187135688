import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const TotalSumChart = ({data, width}) => {

    return (
            <LineChart
                width={width - 30}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" allowDataOverflow={false}/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="linear" dataKey="sum" name='Сумма без скидки'stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="linear" dataKey="total_sum" name='Сумма' stroke="green" strokeWidth={2} />
                {/*<Line type="basis" dataKey="total_sum" name='Средняя' stroke="blue" strokeWidth={2} />*/}
                <Line type="linear" dataKey="orders_count" name='Количество заказов' stroke="black" dot='none'/>
            </LineChart>
    )
}