import axios from "axios";
import {url} from "../../config";

axios.defaults.withCredentials = true


export function GetRestaurantInfoRequest (id){
    return axios.get(`${url}/api/v1/restaurants/${id}`)
}

export function SetWorkStatusRequest (restaurant_id, status){
    return axios.post(`${url}/api/v1/restaurants/${restaurant_id}/setworkstatus`, {
        "status": status,
    })
}