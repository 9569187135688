import axios from "axios";
import {url} from "../../config";

axios.defaults.withCredentials = true


export function GetProductsRequest (id){
    return axios.get(`${url}/api/v1/products/${id}`)
}

export function SetMenuItemPriceRequest (restaurant_id, menuID, price){
    return axios.post(`${url}/api/v1/products/${restaurant_id}/setprice`, {
        "menuId": menuID,
        "price": price
    })
}

export function SetMenuItemStopStatusRequest (restaurant_id, menuID, status){
    return axios.post(`${url}/api/v1/products/${restaurant_id}/setmenuitemstopstatus`, {
        "menuId": menuID,
        "status": status
    })
}

export function SetMenuItemVisibleStatusRequest (restaurant_id, menuID, status){
    return axios.post(`${url}/api/v1/products/${restaurant_id}/setview`, {
        "menuId": menuID,
        "status": status
    })
}