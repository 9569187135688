import logo from "../../logocircle.svg";

import s from './Loading.module.scss'

export const Loading = () => {

    return (
        <div className={s.wrapper}>
            <span>ПОИС</span>
            <img src={logo} alt="Логотип"/>
        </div>
    )
}