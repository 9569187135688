import axios from "axios";
import {url} from "../../config";
import {useCouriersStore} from "./couriersStore";

axios.defaults.withCredentials = true

const { setCouriers } = useCouriersStore.getState()
export function GetCouriersAPI (id, is_working = false){
    axios.get(`${url}/api/v1/couriers/${id}?only_active=${is_working}`).then(function (response) {
        if (response.data.status) {
            setCouriers(response.data.data.couriers)
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}

export function SetCourierWorkStatusAPI (restaurant_id, courierID, status){
    return axios.post(`${url}/api/v1/couriers/${restaurant_id}/setCourierStatus`, {
        "courierID": courierID,
        "status": status,
    }).then(function (response) {
        if (response.data.status) {
            setCouriers(response.data.data.couriers)
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}