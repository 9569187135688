
import s from './Couriers.module.scss'
import {useEffect, useState} from "react";
import {useCouriersStore} from "../../store/couriers/couriersStore";
import {GetCouriersAPI, SetCourierWorkStatusAPI} from "../../store/couriers/couriersAPI";
import {useUserStore} from "../../store/user/userStore";
import {isEmpty} from "lodash";
import {Popup} from "../../components/Popup/Popup";


export const Couriers = () => {
    const { couriers} = useCouriersStore()
    const {pickedRestaurant} = useUserStore()

    const [isOpenPopup, setIsOpenPopup] = useState(false)
    const [courierData, setCourierData] = useState({})

    useEffect(() => {
        if (pickedRestaurant !== undefined) {
            GetCouriersAPI(pickedRestaurant)
        }
    }, []);

    const closePopup = () => {
        setIsOpenPopup(false)
    }

    console.log(couriers)
    return (
        <div className={s.wrapper}>
            <div className={s.info}>
                <h2 className={s.title}>Курьеры</h2>
                {/*<div className={s.content}>*/}
                <div className='w-full border-slate-200 border rounded'>

                    {/*<table className={s.table}>*/}
                    <table className='w-full table-auto rounded bg-slate-50'>
                        <tr>
                            <th className='p-3'>Фамилия</th>
                            <th className='p-3'>Имя</th>
                            <th className='p-3'>Отчество</th>
                            <th className='p-3'>Статус</th>
                        </tr>
                        {!isEmpty(couriers) && couriers.map((item) => {
                            return <tr className='transition-all'>
                                <td className='p-2 text-center hover:bg-slate-100 cursor-pointer'>{item.surname}</td>
                                <td className='p-2 text-center hover:bg-slate-100 cursor-pointer'>{item.name}</td>
                                <td className='p-2 text-center hover:bg-slate-100 cursor-pointer'>{item.second_name}</td>
                                <td className={`p-2 cursor-pointer text-center 
                                ${item.is_working ? 'bg-green-300 hover:bg-green-400' : 'bg-red-300 hover:bg-red-400'}
                                `}
                                    onClick={() => {
                                        setIsOpenPopup(true)
                                        setCourierData(item)
                                    }}
                                >{item.is_working ? 'Работает' : 'Не работает'}</td>
                            </tr>
                        })}
                    </table>
                </div>

            </div>
            {isOpenPopup && <ChangeWorkingStatus closePopup={closePopup} courier={courierData} />}
        </div>
    )
}

const ChangeWorkingStatus = ({closePopup, courier}) => {
    const {pickedRestaurant} = useUserStore()
    const [isLoading, setIsLoading] = useState(false)
    return (
        <Popup close={closePopup}>
            <p className={s.popupTitle}>Изменение статуса курьера</p>
            <div className={s.popupText}>Статус будет установлен на <b>{courier.is_working ? 'не работает' : 'работает'}</b></div>
            <button
                className={s.button}
                disabled={isLoading}
                onClick={ async () => {
                    setIsLoading(true)
                    await SetCourierWorkStatusAPI(pickedRestaurant, courier.id, !courier.is_working)
                    setIsLoading(false)
                }}>Установить</button>
        </Popup>
    )
}