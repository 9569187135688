import { create } from 'zustand'
import {devtools} from 'zustand/middleware'
import {GetRestaurantInfoRequest, SetWorkStatusRequest} from "./restaurantAPI";

export const useRestaurantStore = create(devtools((set) => (
            {
                restaurantdata: {},
                setRestaurantData: async (data) => {
                    set({restaurantdata: data})
                },
                GetRestaurantInfoAPI: async (id) => {
                    GetRestaurantInfoRequest(id).then(function (response) {
                        if (response.data.status) {
                            set({restaurantdata: response.data.data.restaurant})
                        } else {
                            // no auth
                        }
                    }).catch(function (error) {
                        alert('Произошла неизвестная ошибка')
                    })
                },


                SetWorkStatusAPI: async (id, status) => {
                    await SetWorkStatusRequest(id, status).then(function (response) {
                        if (response.data.status) {
                            set({restaurantdata: response.data.data.restaurant})
                        } else {
                            // no auth
                        }
                    }).catch(function (error) {
                        alert('Произошла неизвестная ошибка')
                    })
                },
            }
        ),
        {
            name: "restaurant-storage"
        }
    )
)


