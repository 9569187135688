import axios from "axios";
import {isEmpty} from "lodash";
import {useEffect, useState} from "react";

import {url} from "../../../../config";

import s from './MinimalAmountChanger.module.scss'

import {useRestaurantStore} from "../../../../store/restaurant/restaurantStore";
import {useUserStore} from "../../../../store/user/userStore";


export const MinimalAmountChanger = () => {
    const {restaurantdata, setRestaurantData} = useRestaurantStore()
    const {pickedRestaurant} = useUserStore()

    const [minimalAmount, setMinimalAmount] = useState(0)
    const [isAPIloading, setIsAPIloading] = useState(false)


    useEffect(() => {
        if (!isEmpty(restaurantdata)) {
            setMinimalAmount(restaurantdata.min_sum)
        }
    }, [restaurantdata])


    const ChangeMinimalAmountAPI = async () => {
        setIsAPIloading(true)
        await axios.post(`${url}/api/v1/restaurants/${pickedRestaurant}/setamount`, {
            'type': 'minimumAmount',
            'amount': minimalAmount
        }).then(function (response) {
            if (response.data.status) {
                setRestaurantData(response.data.data.restaurant)
            } else {
                alert(`чёт не получилось\n${response.data.errorMessage}`)
            }
        }).catch(function (error) {
            alert('Произошла неизвестная ошибка')
        })
        setIsAPIloading(false)
    }




    return (
        <section className={s.wrapper}>


            <div className={s.switcherWrapper}>
                <span>Минимальная сумма доставки</span>
                <input
                    type="number"
                    value={minimalAmount}
                    onChange={(e) => setMinimalAmount(e.target.value)}
                    className='border appearance-none rounded px-4 py-2 focus:outline-none focus:shadow-outline text-gray-700 mt-2'
                />
            </div>

            <button
                className={s.button}
                disabled={restaurantdata.min_sum === minimalAmount || isAPIloading}
                onClick={() => ChangeMinimalAmountAPI()}
            >Сохранить
            </button>
        </section>
    )
}