import axios from "axios";
import {url} from "../../config";

axios.defaults.withCredentials = true

export function LoginRequest (username, password){
    return axios.post(`${url}/api/v1/auth/login`, {
        "username": username,
        "password": password
    })
}

export function CheckAuthRequest (){
    return axios.get(`${url}/api/v1/auth/`)
}

