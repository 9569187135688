
import s from './Popup.module.scss'


export const Popup = ({children, close}) => {

    return (
        <div className={s.wrapper}>
            <div className={s.overlay}
                onClick={() => {
                    close(false)
                }}
            >
                <div className={s.content}
                onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
        </div>
)
}