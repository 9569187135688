
import s from './Restaurant.module.scss'
import {useEffect} from "react";
import {useRestaurantStore} from "../../store/restaurant/restaurantStore";
import {useUserStore} from "../../store/user/userStore";
import {RestaurantStatusChanger} from "./components/StatusChanger/RestaurantStatusChanger";
import {MinimalAmountChanger} from "./components/MInimalAmountChanger/MinimalAmountChanger";


export const Restaurant = () => {
    const {GetRestaurantInfoAPI} = useRestaurantStore()
    const {pickedRestaurant} = useUserStore()

    useEffect(() => {
        if (pickedRestaurant !== undefined) {
            GetRestaurantInfoAPI(pickedRestaurant)
        }
    }, []);


    return (
        <div className={s.wrapper}>
            <div className={s.info}>
                <h2 className={s.title}>Настройки ресторана</h2>
                {/*<RestaurantTimeChanger/>*/}
                <RestaurantStatusChanger />
                <MinimalAmountChanger />
            </div>
        </div>
    )
}