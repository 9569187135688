import {useEffect, useRef, useState} from "react";
import axios from "axios";

import {url} from "../../config";

import {useUserStore} from "../../store/user/userStore";

import s from './Main.module.scss'
import {isEmpty, isNil} from "lodash";
import {TotalSumChart} from "../Restaurant/components/Charts/TotalSumChart";

import {Message, useToaster, DateRangePicker} from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import {
    subDays,
    startOfWeek,
    endOfWeek,
    addDays,
    startOfMonth,
    addMonths,
    endOfMonth,
    startOfDay,
    endOfDay
} from 'date-fns';

export const Main = () => {
    const {pickedRestaurant} = useUserStore()
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [fromDate, setFromDate] = useState(new Date().toISOString());
    const [toDate, setToDate] = useState(new Date().toISOString());

    const [placement, setPlacement] = useState('bottomEnd');
    const toaster = useToaster();


    const getStatsAPI = async () => {
        setIsLoading(true)
        await axios.get(`${url}/api/v1/stats/${pickedRestaurant}/getDefaultInfo?from_date=${fromDate}&to_date=${toDate}`,
            {withCredentials: true}).then((response) => {
            if (response.data.status) {
                setData(response.data.data)
            } else {
                alert(response.data.errorMessage)
            }
            toaster.push(message, { placement, duration: 1000 })
        })
        setIsLoading(false)
    }

    const parentRef = useRef(null);
    const [parentWidth, setParentWidth] = useState(0)
    useEffect(() => {
        if (parentRef.current) {
            const parentWidth = parentRef.current.clientWidth;
            setParentWidth(parentWidth)
        }
    }, []);


    const setFromDateWithTZ = (date) => {
        date.setHours(date.getHours() + 3)
        setFromDate(date.toISOString())
    }
    const setToDateWithTZ = (date) => {
        date.setHours(date.getHours() + 3)
        setToDate(date.toISOString())
    }

    function roundToDecimal(num, decimalPlaces) {
        let factor = Math.pow(10, decimalPlaces);
        return Math.round(num * factor) / factor;
    }


    const message = (
        <Message showIcon type={'success'} closable>
            Отчет сгенерирован успешно
        </Message>
    );

    const predefinedRanges = [
        {
            label: 'Сегодня',
            value: [startOfDay(new Date()), endOfDay(new Date())],
            placement: 'bottom'
        },
        {
            label: 'Вчера',
            value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
            placement: 'bottom'
        },
        {
            label: 'Эта неделя',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'bottom'
        },
        {
            label: 'Последние 7 дней',
            value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
            placement: 'bottom'
        },
        {
            label: 'Последние 30 дней',
            value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())],
            placement: 'bottom'
        },
        {
            label: 'Этот месяц',
            value: [startOfMonth(new Date()), endOfDay(new Date())],
            placement: 'bottom'
        },
        {
            label: 'Последний месяц',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'bottom'
        },
        {
            label: 'Этот год',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'bottom'
        },
        {
            label: 'Последняя неделя',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
    ];

    return (
        <div className='w-full h-full p-3 bg-gray-100'>
            <div className='mb-[10px] w-full bg-white rounded p-[10px]'>

                <DateRangePicker
                    onChange={(date) => {
                        setFromDateWithTZ(date[0])
                        setToDateWithTZ(date[1])
                    }}
                    ranges={predefinedRanges}
                    showOneCalendar
                    placeholder="Выберите диапазон"
                    style={{width: 300, margin: 10}}
                    character={' - '}
                    preventOverflow={false}
                    placement={'auto'}
                    showHeader={false}
                />
                <button
                    onClick={() => getStatsAPI()}
                    className='p-[6px] rounded bg-blue-500 text-white'
                >Сгенерировать
                </button>
            </div>


            {isLoading ? <div>Loading...</div> :
                <>
                    <div
                        className='w-full bg-white rounded-2xl shadow-lgxl p-3'
                        ref={parentRef}
                    >
                        <h3 className='font-bold text-4xl mb-5'>Общая статистика</h3>
                        <div className='flex flex-row flex-wrap mb-10'>
                            <Metrica title={'Общая выручка без скидок'} amount={data.amount} char={'р'}/>
                            <Metrica title={'Общая выручка'} amount={data.total_amount} char={'р'}/>
                            <Metrica title={'Средний чек'} amount={data.average_check} char={'р'}/>
                            <Metrica title={'Средняя цена за позиию'} amount={data.average_position_amount} char={'р'}/>
                            <Metrica title={'Оформлено заказов'} amount={data.orders_count}/>
                            <Metrica title={'Продано продуктов'} amount={data.product_count}/>
                            <Metrica title={'Возврат'} amount={roundToDecimal(data.define_orders_count / data.define_users_count, 2)}/>
                            <Metrica title={'КОЗ'} amount={data.define_orders_count}/>
                            <Metrica title={'КОП'} amount={data.define_users_count}/>
                        </div>

                        {!isNil(data.chart) && <TotalSumChart data={data.chart} width={parentWidth}/>}
                    </div>

                    <div className={s.content}>
                        <h3 className='font-bold text-4xl mb-4 mt-7'>Статистика доставки</h3>
                        <div className={s.metrics}>
                            {!isEmpty(data) &&
                                <>
                                    <Metrica title={'Общая выручка без скидок'} amount={data.delivery.amount} char={'р'}/>
                                    <Metrica title={'Общая выручка'} amount={data.delivery.total_amount} char={'р'}/>
                                    <Metrica title={'Средний чек'} amount={data.delivery.average_check} char={'р'}/>
                                    <Metrica title={'Средняя цена за позиию'} amount={data.delivery.average_product_amount}
                                             char={'р'}/>
                                    <Metrica title={'Оформлено заказов'} amount={data.delivery.orders_count}/>
                                    <Metrica title={'Продано продуктов'} amount={data.delivery.product_count}/>
                                </>
                            }
                        </div>
                    </div>

                    <div className={s.content}>
                        <h3 className='font-bold text-4xl mb-4 mt-7'>Статистика ресторана</h3>
                        <div className={s.metrics}>
                            {!isEmpty(data) &&
                                <>
                                    <Metrica title={'Общая выручка без скидок'} amount={data.inside.amount} char={'р'}/>
                                    <Metrica title={'Общая выручка'} amount={data.inside.total_amount} char={'р'}/>
                                    <Metrica title={'Средний чек'} amount={data.inside.average_check} char={'р'}/>
                                    <Metrica title={'Средняя цена за позиию'}
                                             amount={data.inside.average_product_amount} char={'р'}/>
                                    <Metrica title={'Оформлено заказов'} amount={data.inside.orders_count}/>
                                    <Metrica title={'Продано продуктов'} amount={data.inside.product_count}/>
                                </>
                            }
                        </div>
                    </div>
                </>
            }

            <div className='flex flex-col'>
                <h2 className='mt-10 text-[40px] font-black'>Ссылки</h2>
                <a
                    className='text-blue-600 text-[25px] font-semibold'
                    target='_blank'
                    href="https://cashier.poissystem.ru">Касса на доставку</a>
                <a
                    className='text-blue-600 text-[25px] font-semibold'
                    target='_blank'
                    href="https://tracker.poissystem.ru/cashier">Касса внутри</a>
                <a
                    className='text-blue-600 text-[25px] font-semibold'
                    target='_blank'
                    href="https://tracker.poissystem.ru/manager">Борды</a>
            </div>
        </div>
    )
}


const Metrica = ({title, amount, char = ''}) => {
    return (
        <div className='flex flex-col bg-slate-100 rounded  p-3 w-1/2'>
            <div className='text-center font-semibold'>{title}</div>
            <div className='text-center '>{amount}{char !== '' && char}</div>
        </div>
    )
}