
import s from './RestaurantStatusChanger.module.scss'
import {useEffect, useState} from "react";
import {useRestaurantStore} from "../../../../store/restaurant/restaurantStore";
import {isEmpty} from "lodash";
import {useUserStore} from "../../../../store/user/userStore";
import axios from "axios";
import {url} from "../../../../config";

export const RestaurantStatusChanger = () => {
    const {restaurantdata, setRestaurantData} = useRestaurantStore()
    const {pickedRestaurant} = useUserStore()

    const [restaurantWorking, setRestaurantWorking] = useState(false)
    const [isWorkingAPIloading, setIsWorkingAPIloading] = useState(false)
    const [restaurantDelivery, setRestaurantDelivery] = useState(false)
    const [isDeliveryAPIloading, setIsDeliveryAPIloading] = useState(false)


    useEffect(() => {
        if (!isEmpty(restaurantdata)) {
            setRestaurantWorking(restaurantdata.working)
            setRestaurantDelivery(restaurantdata.delivery)
        }
    }, [restaurantdata])


    const ChangeWorkingStatusAPI = async () => {
        setIsWorkingAPIloading(true)
        await axios.post(`${url}/api/v1/restaurants/${pickedRestaurant}/setworkstatus`, {
            "status": restaurantWorking,
        }).then(function (response) {
            if (response.data.status) {
                setRestaurantData(response.data.data.restaurant)
            } else {
                alert('чёт не получилось')
            }
        }).catch(function (error) {
            alert('Произошла неизвестная ошибка')
        })
        setIsWorkingAPIloading(false)
    }

    const ChangeDeliveryStatusAPI = async () => {
        setIsDeliveryAPIloading(true)
        await axios.post(`${url}/api/v1/restaurants/${pickedRestaurant}/setdeliverystatus`, {
            "status": restaurantDelivery,
        }).then(function (response) {
            if (response.data.status) {
                setRestaurantData(response.data.data.restaurant)
            } else {
                alert('чёт не получилось')
            }
        }).catch(function (error) {
            alert('Произошла неизвестная ошибка')
        })
        setIsDeliveryAPIloading(false)
    }



    return (
        <section className={s.wrapper}>
            <div className={s.switcherWrapper}>
                <span>Статус работы</span>
                <div className={s.switcher}>
                    <div
                        onClick={() => !isWorkingAPIloading && setRestaurantWorking(true)}
                        className={restaurantWorking ? s._active : ''}>Работает
                    </div>
                    <div
                        onClick={() => !isWorkingAPIloading && setRestaurantWorking(false)}
                        className={!restaurantWorking ? s._active : ''}>Не работает
                    </div>
                </div>
            </div>

            <button
                className={s.button}
                disabled={restaurantdata.working === restaurantWorking || isWorkingAPIloading}
                onClick={() => ChangeWorkingStatusAPI()}
            >Сохранить
            </button>

            <div className={s.switcherWrapper}>
                <span>Статус доставки</span>
                <div className={s.switcher}>
                    <div
                        onClick={() => !isDeliveryAPIloading && setRestaurantDelivery(true)}
                        className={restaurantDelivery ? s._active : ''}>Доставляет
                    </div>
                    <div
                        onClick={() => !isDeliveryAPIloading && setRestaurantDelivery(false)}
                        className={!restaurantDelivery ? s._active : ''}>Не доставляет
                    </div>
                </div>
            </div>

            <button
                className={s.button}
                disabled={restaurantdata.delivery === restaurantDelivery || isDeliveryAPIloading}
                onClick={() => ChangeDeliveryStatusAPI()}
            >Сохранить
            </button>
        </section>
    )
}