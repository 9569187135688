
import s from './Product.module.scss'
import {Popup} from "../../../../components/Popup/Popup";
import {useProductsStore} from "../../../../store/products/productsStore";
import {useEffect, useState} from "react";
import {useUserStore} from "../../../../store/user/userStore";
import axios from "axios";
import {url} from "../../../../config";

export const Product = ({data}) => {
    const [isOpenChangePrice, setIsOpenChangePrice] = useState(false)
    const [isOpenChangeMenuStopStatus, setIsOpenChangeMenuStopStatus] = useState(false)
    const [isOpenChangeProductStopStatus, setIsOpenChangeProductStopStatus] = useState(false)
    const [isOpenChangeVisibleStatus, setIsOpenChangeVisibleStatus] = useState(false)
    const [isOpenChangePriority, setIsOpenChangePriority] = useState(false)
    const [actualIndex, setActualIndex] = useState()

    useEffect(() => {
    }, [data])

    return (
        // <article className={s.wrapper}>
        <article className='
            flex justify-between
             bg-white box-border p-[10px] rounded-[12px] overflow-x-scroll'>
            {/*<div className={s.img}>*/}
            <div className='min-w-[120px] w-[120px] flex justify-center items-center'>
                <img
                    className='w-full'
                    src={data.img}
                    alt={data.title}/>
            </div>
            <div className='mr-[5px] ml-[5px]'>
                <div className={s.title}>{data.title}</div>

                <div className={s.modifications}>
                    <div className={s.modificationTitles}>
                        <div className={s.size}>Размер</div>
                        <div>Цена</div>
                        <div>В наличии</div>
                        <div>Отображается</div>
                    </div>
                    {data.id.map((id, index) => {
                        return (
                            <div className={s.modification} key={index}>
                                <div className={s.size}>{data.sizes[index]}</div>
                                <div
                                    className='bg-slate-100 hover:bg-slate-200 transition'
                                    onClick={() => {
                                    setIsOpenChangePrice(true)
                                    setActualIndex(index)
                                }}>{data.prices[index]}р
                                </div>
                                <div
                                    className={data['in_stock'][index] ? 'bg-slate-100 hover:bg-slate-200 transition' : 'bg-red-300 hover:bg-red-500 transition'}
                                    onClick={() => {
                                    setIsOpenChangeMenuStopStatus(true)
                                    setActualIndex(index)
                                }}>{data.in_stock[index] ? 'Да' : 'Нет'}</div>
                                <div
                                    className='bg-slate-100 hover:bg-slate-200 transition'
                                    onClick={() => {
                                    setIsOpenChangeVisibleStatus(true)
                                    setActualIndex(index)
                                }}>{data['visible'][index] ? 'Да' : 'Нет'}</div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/*<button className={s.button} onClick={() => setIsOpenChangeProductStopStatus(true)}>Стоп</button>*/}
            <div className=' flex flex-col box-border '>
                <button className='w-[70px] h-1/2 bg-primary text-white rounded-[8px] mb-1'
                        onClick={() => setIsOpenChangePriority(true)}>{data.priority}
                </button>
                <button className='w-[70px] h-1/2 bg-primary text-white rounded-[8px] '
                        onClick={() => setIsOpenChangeProductStopStatus(true)}>Стоп
                </button>
            </div>

            {isOpenChangePrice && <ChangePrice close={setIsOpenChangePrice} actualIndex={actualIndex} data={data}/>}
            {isOpenChangeMenuStopStatus &&
                <ChangeMenuStopStatus close={setIsOpenChangeMenuStopStatus} actualIndex={actualIndex} data={data}/>}
            {isOpenChangeVisibleStatus &&
                <ChangeVisibleStatus close={setIsOpenChangeVisibleStatus} actualIndex={actualIndex} data={data}/>}
            {isOpenChangeProductStopStatus &&
                <ChangeProductStopStatus close={setIsOpenChangeProductStopStatus} data={data}/>}
            {isOpenChangePriority &&
                <ChangePriority close={setIsOpenChangePriority} data={data}/>}
        </article>
    )
}

const ChangePrice = ({close, actualIndex, data}) => {
    const {SetMenuItemPriceAPI} = useProductsStore()
    const {pickedRestaurant} = useUserStore()
    const [inputAmount, setInputAmount] = useState(1)
    const [isLoadRequest, setIsLoadRequest] = useState(false)
    return (
        <Popup close={close}>
            <h6 className={s.popupTitle}><span>Изменение цены</span><br/> {data.title} {data.sizes[actualIndex]}</h6>
            <div className={s.row}>
                <span>Текущая цена: </span>
                <span>{data.prices[actualIndex]}р</span>
            </div>
            <div className={s.row}>
                <span>Новая цена: </span>
                <input
                        value={inputAmount}
                        onChange={(e) => setInputAmount(e.target.value)}
                        type={'number'}
                        maxLength={4}
                    />
                </div>

            <button
                disabled={isLoadRequest || inputAmount < 1}
                className={s.button}
                onClick={async () => {
                    setIsLoadRequest(true)
                    await SetMenuItemPriceAPI(pickedRestaurant, data.id[actualIndex], inputAmount)
                    setIsLoadRequest(false)
                    close()
                }}
            >Сохранить</button>
        </Popup>
    )
}

const ChangePriority = ({close, actualIndex, data}) => {
    const {setProductsData} = useProductsStore()
    const {pickedRestaurant} = useUserStore()
    const [inputAmount, setInputAmount] = useState(1)
    const [isLoadRequest, setIsLoadRequest] = useState(false)

    const SetPriorityAPI = async (menuID, number) => {
        return axios.post(`${url}/api/v1/products/${pickedRestaurant}/setpriority`, {
            "menuId": menuID,
            "number": number
        }).then(function (response) {
            if (response.data.status) {
                setProductsData(response.data.data.products)
            } else {
                alert(response.data.errorMessage)
            }
        }).catch(function (error) {
            alert('Произошла неизвестная ошибка')
        })
    }
    return (
        <Popup close={close}>
            <h6 className={s.popupTitle}><span>Изменение приоритета</span><br/> {data.title}</h6>
            {/*<div className={s.row}>*/}
            <div className='w-full'>
                <span className='mr-7'>Новый приоритет: </span>
                <input
                    value={inputAmount}
                    onChange={(e) => setInputAmount(e.target.value)}
                    type={'number'}
                    maxLength={4}
                />
            </div>

            <button
                disabled={isLoadRequest || inputAmount < 1}
                className={s.button}
                onClick={async () => {
                    setIsLoadRequest(true)
                    await SetPriorityAPI(data.id[0], inputAmount)
                    setIsLoadRequest(false)
                    close()
                }}
            >Сохранить</button>
        </Popup>
    )
}

const ChangeProductStopStatus = ({close, data}) => {
    const {setProductsData} = useProductsStore()
    const {pickedRestaurant} = useUserStore()

    const [inStock, setInStock] = useState(data.in_stock[0])
    const [isLoadRequest, setIsLoadRequest] = useState(false)

    const ChangeProductStopStatusAPI = async () => {
        await axios.post(`${url}/api/v1/products/${pickedRestaurant}/setproductitemstopstatus`, {
            "menuId": data.id[0],
            "status": inStock
        }).then(function (response) {
            if (response.data.status) {
                setProductsData(response.data.data.products)
            } else {
                alert(`чёт не получилось\n${response.data.errorMessage}`)
            }
        }).catch(function (error) {
            alert('Произошла неизвестная ошибка')
        })
    }
    return (
        <Popup close={close}>
            <h6 className={s.popupTitle}><span>Изменение стоп статуса</span><br/> {data.title}
            </h6>
            <div className={s.switcher}>
                <div
                    onClick={() => setInStock(false)}
                    className={!inStock ? s._active : ''}>На стопе
                </div>
                <div
                    onClick={() => setInStock(true)}
                    className={inStock ? s._active : ''}>Продается
                </div>
            </div>

            <button
                disabled={isLoadRequest}
                className={s.button}
                onClick={async () => {
                    setIsLoadRequest(true)
                    await ChangeProductStopStatusAPI()
                    setIsLoadRequest(false)
                    close()
                }}
            >Сохранить
            </button>
        </Popup>
    )
}

const ChangeMenuStopStatus = ({close, actualIndex, data}) => {
    const {SetMenuItemStopStatusAPI} = useProductsStore()
    const {pickedRestaurant} = useUserStore()

    const [inStock, setInStock] = useState(data.in_stock[actualIndex])
    const [isLoadRequest, setIsLoadRequest] = useState(false)


    return (
        <Popup close={close}>
            <h6 className={s.popupTitle}><span>Изменение стоп статуса</span><br/> {data.title} {data.sizes[actualIndex]}
            </h6>
            <div className={s.switcher}>
                <div
                    onClick={() => setInStock(false)}
                    className={!inStock ? s._active : ''}>На стопе
                </div>
                <div
                    onClick={() => setInStock(true)}
                    className={inStock ? s._active : ''}>Продается
                </div>
            </div>

            <button
                disabled={isLoadRequest || inStock === data.in_stock[actualIndex]}
                className={s.button}
                onClick={async () => {
                    setIsLoadRequest(true)
                    await SetMenuItemStopStatusAPI(pickedRestaurant, data.id[actualIndex], inStock)
                    setIsLoadRequest(false)
                    close()
                }}
            >Сохранить
            </button>
        </Popup>
    )
}

const ChangeVisibleStatus = ({close, actualIndex, data}) => {
    const {SetMenuItemVisibleStatusAPI} = useProductsStore()
    const {pickedRestaurant} = useUserStore()

    const [isVisible, setIsVisible] = useState(data.visible[actualIndex])
    const [isLoadRequest, setIsLoadRequest] = useState(false)

    useEffect(() => {
        setIsVisible(data.visible[actualIndex])
    }, [data]);


    return (
        <Popup close={close}>
            <h6 className={s.popupTitle}><span>Изменение статуса отображения</span><br/> {data.title} {data.sizes[actualIndex]}
            </h6>
            <div className={s.switcher}>
                <div
                    onClick={() => setIsVisible(false)}
                    className={!isVisible ? s._active : ''}>Не отображается
                </div>
                <div
                    onClick={() => setIsVisible(true)}
                    className={isVisible ? s._active : ''}>Отображается
                </div>
            </div>

            <button
                disabled={isLoadRequest || isVisible === data.in_stock[actualIndex]}
                className={s.button}
                onClick={async () => {
                    setIsLoadRequest(true)
                    await SetMenuItemVisibleStatusAPI(pickedRestaurant, data.id[actualIndex], isVisible)
                    setIsLoadRequest(false)
                    close()
                }}
            >Сохранить
            </button>
        </Popup>
    )
}
