
import s from './Auth.module.scss'
import {useUserStore} from "../../store/user/userStore";
import {useState} from "react";
import {useNavigate} from "react-router-dom";


export const Auth = () => {
    const {LoginAPI, errorAuthMessage} = useUserStore()
    const navigate = useNavigate();


    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')


    function goToMainPage() {
        navigate("/");
    }

    return (
        <main className={s.wrapper}>
            <div className={s.authForm}>
                <h2 className={s.title}>Войдите в систему</h2>

                <div className={s.input}>
                    <p>Логин</p>
                    <input
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className={s.input}>
                    <p>Пароль</p>
                    <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <span className={s.errorMessage}>{errorAuthMessage}</span>

                <button
                    className={s.button}
                    onClick={() => LoginAPI(username, password, goToMainPage)}
                >Вход</button>
            </div>
        </main>
    )
}