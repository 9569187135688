import './App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import {Auth} from "./pages/Auth/Auth";
import {useEffect} from "react";
import {useUserStore} from "./store/user/userStore";

import {Loading} from "./pages/Loading/Loading";

import {Main} from "./pages/Main/Main";
import {Layout} from "./components/Layout/Layout";
import {Restaurant} from "./pages/Restaurant/Restaurant";
import {Products} from "./pages/Products/Products";
import {Couriers} from "./pages/Couriers/Couriers";
function App() {
    const {CheckAuthAPI, isLoadingCheckAuthRoute} = useUserStore()
    const navigate = useNavigate();

    function goToAuthPage() {
        navigate("/auth");
    }

    useEffect(() => {
        CheckAuthAPI(goToAuthPage)
    }, [])


    if (isLoadingCheckAuthRoute) {
        return (
            <Loading />
        )
    }

    return (
          <Routes>
              <Route path="/auth" element={<Auth />} />
              <Route path="/" element={<Layout />}>
                  <Route index element={<Main />} />
                  <Route path="restaurant" element={<Restaurant />} />
                  <Route path="products" element={<Products />} />
                  <Route path="couriers" element={<Couriers />} />
                  <Route path="*" element={<div>такой страницы нет :(</div>} />

              </Route>
          </Routes>
    );
}

export default App;
