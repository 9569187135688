import { Outlet } from 'react-router-dom';
import {Navigation} from "../Navigation/Navigation";

import s from './Layout.module.scss'

const Layout = () => {
    return (
        <div className={s.container}>
            <Navigation />
            <main className={s.main}>
                <Outlet />
            </main>
        </div>
    )
}

export {Layout}