import { create } from 'zustand'
import {devtools} from 'zustand/middleware'
import {CheckAuthRequest, LoginRequest} from "./userAPI";

export const useUserStore = create(devtools((set) => (
            {
                userdata: {},
                permissions: {},
                restaurants: [],

                isAuth: false,
                errorAuthMessage: '',
                LoginAPI: async (username, password, goToMainPage) => {
                    LoginRequest(username, password).then(function (response) {
                        if (response.data.status) {
                            set({isAuth: true})
                            set({errorAuthMessage: ''})
                            set({userdata: response.data.data.userdata})
                            set({permissions: response.data.data.permissions})
                            set({restaurants: response.data.data.restaurants})
                            set({pickedRestaurant: response.data.data.restaurants[0]})
                            goToMainPage()
                        } else {
                            set({errorAuthMessage: response.data.errorMessage})
                        }
                    }).catch(function (error) {
                        set({isAuth: false})
                        set({errorAuthMessage: 'Произошла неизвестная ошибка'})
                    })
                },

                isLoadingCheckAuthRoute: false,
                CheckAuthAPI: async (goToAuthPage) => {
                    set({isLoadingCheckAuthRoute: true})
                    await CheckAuthRequest().then(function (response) {
                        if (response.data.status) {
                            set({isAuth: true})
                            set({userdata: response.data.data.userdata})
                            set({permissions: response.data.data.permissions})
                            set({restaurants: response.data.data.restaurants})
                            set({pickedRestaurant: response.data.data.restaurants[0]})
                        } else {
                            goToAuthPage()
                            set({isAuth: false})
                        }
                    }).catch(function (error) {
                        alert('Произошла неизвестная ошибка')
                        goToAuthPage()
                        set({isAuth: false})
                    })
                    set({isLoadingCheckAuthRoute: false})
                },


                pickedRestaurant: undefined,
                setPickedRestaurant: (int) => {
                    set({pickedRestaurant: int})
                },

            }
        ),
        {
            name: "user-storage"
        }
    )
)


