import { create } from 'zustand'
import {devtools} from 'zustand/middleware'
import {
    GetProductsRequest,
    SetMenuItemPriceRequest,
    SetMenuItemStopStatusRequest,
    SetMenuItemVisibleStatusRequest
} from "./productsAPI";

export const useProductsStore = create(devtools((set) => (
            {
                productsdata: {},
                setProductsData: (data) => {
                    set({productsdata: data})
                },
                GetProductsAPI: async (id) => {
                    await GetProductsRequest(id).then(function (response) {
                        if (response.data.status) {
                            set({productsdata: response.data.data.products})
                        } else {
                            alert(response.data.errorMessage)
                        }
                    }).catch(function (error) {
                        alert('Произошла неизвестная ошибка')
                    })
                },

                isOpenProductPopup: true,
                setIsOpenProductPopup: (bool) => {
                    set({isOpenProductPopup: bool})
                },

                SetMenuItemPriceAPI: async (id, menuID, price) => {
                    await SetMenuItemPriceRequest(id, menuID, price).then(function (response) {
                        if (response.data.status) {
                            set({productsdata: response.data.data.products})
                        } else {
                            alert(response.data.errorMessage)
                        }
                    }).catch(function (error) {
                        alert('Произошла неизвестная ошибка')
                    })
                },

                SetMenuItemStopStatusAPI: async (id, menuID, status) => {
                    await SetMenuItemStopStatusRequest(id, menuID, status).then(function (response) {
                        if (response.data.status) {
                            set({productsdata: response.data.data.products})
                        } else {
                            alert(response.data.errorMessage)
                        }
                    }).catch(function (error) {
                        alert('Произошла неизвестная ошибка')
                    })
                },

                SetMenuItemVisibleStatusAPI: async (id, menuID, status) => {
                    await SetMenuItemVisibleStatusRequest(id, menuID, status).then(function (response) {
                        if (response.data.status) {
                            set({productsdata: response.data.data.products})
                        } else {
                            alert(response.data.errorMessage)
                        }
                    }).catch(function (error) {
                        alert('Произошла неизвестная ошибка')
                    })
                },
            }
        ),
        {
            name: "products-storage"
        }
    )
)


