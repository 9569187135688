import {isEmpty} from "lodash";
import {useEffect} from "react";

import s from './Products.module.scss'

import {useUserStore} from "../../store/user/userStore";
import {useProductsStore} from "../../store/products/productsStore";

import {Product} from './components/Product/Product'

export const Products = () => {
    const {GetProductsAPI, productsdata} = useProductsStore()
    const {pickedRestaurant} = useUserStore()

    useEffect(() => {
        if (pickedRestaurant !== undefined) {
            GetProductsAPI(pickedRestaurant)
        }
    }, []);


    if (isEmpty(productsdata)) {
        return ''
    }
    return (
        // <div className={s.wrapper}>
        <div className='w-full bg-slate-50 p-[10px]'>
            {/*<h2 className={s.title}>Продукты</h2>*/}
            <h2 className='text-[30px] text-gray-400'>Продукты</h2>
            {productsdata.map((category) => {
                return (
                    <>
                        {/*<p className={s.categoryTitle}>{category.type}</p>*/}
                        <p className='font-bold text-[30px] mb-5 mt-10'>{category.type}</p>
                        {/*<div className={s.categoryItems}>{*/}
                        <div className='w-full
                        grid grid-cols-1
                        lg:grid-cols-2 gap-2
                        '>{
                            category.items.map((product) => {
                                return <Product data={product} />
                            })
                        }</div>
                    </>
                )
            })}
        </div>
    )
}